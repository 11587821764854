import * as React from "react";
import {
  PartObject,
  DataContainerTypeData,
  AttributeData,
  isDCTypeData,
  ScrollWindow,
  ReferenceValueData,
  ReferenceTypeData,
  isRefTypeData,
  DataContainerValueData,
} from "./Types";
import { Checkbox, ArrowLeft, ArrowRight } from "tim-ui";
import { i18n } from "tim-bridge";
import ChangeInformation from "./ChangeInformation";
import styled from "styled-components";
import { getCell } from "./utils";
import { TableRow, SmallArrowLeft, SmallArrowRight } from "./styled";

interface DCTableRowProps {
  partObject: PartObject<DataContainerTypeData>;
  selected: string[];
  selectionCallback: (ids: string[], s: boolean) => void;
}

export const removedFromMainString: string = i18n.t(
  "PartialApprovalDialog.removedFromMainString",
  { defaultValue: "This item was removed from main workspace" }
);

export const StyledCell = styled.td`
  vertical-align: top;
  padding-bottom: 8px;
  padding-right: 8px;
  padding-top: 12px;
  background-color: ${(props) => (props.removed ? "#f4c9c9" : undefined)};
`;

export const StyledCBCell = styled(StyledCell)`
  padding-top: 10px;
  padding-bottom: 6px;
  padding-left: 16px;
  text-align: left;
`;

const StyledLastCell = styled(StyledCell)`
  padding-left: 16px;
`;

const StyledDCTableCell = styled(StyledCell)`
  padding-bottom: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  border-left: 1px solid #979797;
  border-right: 1px solid #979797;
`;

const ScrollerLeft = styled.td`
  width: 14px;
  border-right: 1px solid #979797;
  font-size: 16px;
`;

const ScrollerRight = styled.td`
  width: 14px;
  border-right: 1px solid #979797;
  font-size: 16px;
`;

export const DCTableRow = (props: DCTableRowProps) => {
  const { selected, selectionCallback, partObject } = props;
  const [scrollWindow, setScrollWindow] = React.useState<ScrollWindow>({
    startIndex: 0,
    endIndex: 2,
    size: 3,
    initial: true,
    dir: "none",
  });

  const moveRight = () => {
    const totalRowSize =
      partObject.data.value[0].value.length +
      partObject.data.value[0].refs.length;
    if (scrollWindow.endIndex < totalRowSize - 1) {
      setScrollWindow({
        startIndex: scrollWindow.startIndex + 1,
        endIndex: scrollWindow.endIndex + 1,
        size: scrollWindow.size,
        initial: false,
        dir: "right",
      });
    }
  };

  const moveLeft = () => {
    if (scrollWindow.startIndex > 0) {
      setScrollWindow({
        startIndex: scrollWindow.startIndex - 1,
        endIndex: scrollWindow.endIndex - 1,
        size: scrollWindow.size,
        initial: false,
        dir: "left",
      });
    }
  };
  const handleCheck = (c: boolean, id: string) => {
    let typeSelected = id === partObject.title;
    let ids = typeSelected ? partObject.data.value.map((v) => v.diffId) : [id];
    selectionCallback(ids, c);
  };

  let enableScrollLeft: boolean = scrollWindow.startIndex > 0;
  let enableScrollRight: boolean =
    scrollWindow.endIndex < partObject.data.value[0].value.length - 1;
  let scrollingEnabled: boolean = enableScrollRight || enableScrollLeft;
  let valueSpan = !scrollingEnabled ? 3 : 1;

  const createDCInstanceHeader = (referred: DataContainerTypeData) => {
    const attsAndRefs = (referred.value[0].value as [
      AttributeData | ReferenceTypeData
    ]).concat(referred.value[0].refs);

    return (
      <StyledDCTableCell style={{ height: "1px" }} colSpan={valueSpan}>
        <TableRow header>
          {attsAndRefs.map((attribute: AttributeData | ReferenceTypeData) =>
            getCell(
              false,
              scrollWindow,
              attsAndRefs.indexOf(attribute),
              attribute.id,
              isRefTypeData(attribute) ? attribute.title : attribute.title,
              attsAndRefs.length > scrollWindow.size
                ? 100 / scrollWindow.size
                : 100 / attsAndRefs.length,
              true
            )
          )}
        </TableRow>
      </StyledDCTableCell>
    );
  };

  const createDCInstanceRow = (dcValue: DataContainerValueData) => {
    const attsAndRefs = (dcValue.value as [
      AttributeData | ReferenceTypeData
    ]).concat(dcValue.refs);
    const cellWidth =
      attsAndRefs.length > scrollWindow.size
        ? 100 / scrollWindow.size
        : 100 / attsAndRefs.length;
    const cells = attsAndRefs.map((att) =>
      getCell(
        dcValue.removed,
        scrollWindow,
        attsAndRefs.indexOf(att),
        dcValue.id + ":" + att.id,
        isRefTypeData(att)
          ? att.value.map((rv) => rv.target).join("<multisep/>")
          : att.value,
        cellWidth,
        false
      )
    );
    return <TableRow removed={dcValue.removed}>{cells}</TableRow>;
  };

  const createScrollers = () => {
    return scrollingEnabled ? (
      <>
        <ScrollerLeft
          id={props.partObject.title + "-prev"}
          onClick={() => moveLeft()}
          rowSpan={partObject.data.value.length + 1}
        >
          <SmallArrowLeft fontSize="inherit" />
        </ScrollerLeft>
        <ScrollerRight
          id={props.partObject.title + "-next"}
          onClick={() => moveRight()}
          rowSpan={partObject.data.value.length + 1}
        >
          <SmallArrowRight fontSize="inherit" />
        </ScrollerRight>
      </>
    ) : undefined;
  };

  const getCB = (id: string) => {
    let checked =
      selected.includes(id) ||
      (id === partObject.title &&
        partObject.data.value
          .map((dcv) => dcv.diffId)
          .every((id) => selected.indexOf(id) > -1));

    let approvable = true;
    return approvable ? (
      <Checkbox
        id={id}
        onChange={(event) => handleCheck(event.target.checked, event.target.id)}
        checked={checked}
      />
    ) : null;
  };
  const dcrows = partObject.data.value.map((dcv) => (
    <tr key={"DCV" + dcv.diffId}>
      <StyledCBCell
        title={dcv.removed ? removedFromMainString : undefined}
        removed={dcv.removed}
      >
        {getCB(dcv.diffId)}
      </StyledCBCell>
      <StyledCell
        title={dcv.removed ? removedFromMainString : undefined}
        removed={dcv.removed}
      >
        Data Container
      </StyledCell>
      <StyledCell
        title={dcv.removed ? removedFromMainString : undefined}
        removed={dcv.removed}
      >
        {dcv.id}
      </StyledCell>
      <StyledDCTableCell
        title={dcv.removed ? removedFromMainString : undefined}
        removed={dcv.removed}
        colSpan={valueSpan}
      >
        {createDCInstanceRow(dcv)}
      </StyledDCTableCell>
      <StyledLastCell
        title={dcv.removed ? removedFromMainString : undefined}
        removed={dcv.removed}
      >
        {dcv.info}
      </StyledLastCell>
    </tr>
  ));
  return (
    <>
      <tr style={{ borderTop: "1px solid #979797" }}>
        <StyledCBCell>
          {getCB(
            isDCTypeData(partObject.data) ? partObject.title : partObject.id
          )}
        </StyledCBCell>
        <StyledCell>{partObject.typeTitle + " type"}</StyledCell>
        <StyledCell>{partObject.title}</StyledCell>
        {createDCInstanceHeader(partObject.data)}
        {createScrollers()}
        <StyledLastCell>
          <ChangeInformation
            responsibleUser={partObject.responsibleUser}
            changed={partObject.changed}
          />
        </StyledLastCell>
      </tr>
      {dcrows}
    </>
  );
};

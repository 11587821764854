import {
  PartObject,
  ValueData,
  isRefTypeData,
  ReferenceValueData,
  isDCTypeData,
  DataContainerTypeData,
  isAttributeData,
} from "./Types";
import * as React from "react";
import ChangeInformation from "./ChangeInformation";
import { Checkbox } from "tim-ui";
import styled from "styled-components";
import { DCTableRow, StyledCBCell } from "./PartObjectsDCTableRow";
import { formatValue, canBeApproved } from "./utils";
import { ReferenceTypeTable } from "./ReferenceTypeTable";

interface TableRowProps {
  partObject: PartObject<ValueData>;
  selected: string[];
  selectionCallback: (ids: string[], s: boolean) => void;
}

export const StyledCell = styled.td`
  vertical-align: top;
  padding-bottom: 8px;
  padding-right: 8px;
  padding-top: 12px;
`;

export const StyledLastCell = styled(StyledCell)`
  padding-left: 16px;
`;

export const PartObjectsTableRow = (props: TableRowProps) => {
  const { partObject, selected, selectionCallback } = props;

  if (isDCTypeData(partObject.data)) {
    return (
      <DCTableRow
        partObject={partObject as PartObject<DataContainerTypeData>}
        selected={selected}
        selectionCallback={selectionCallback}
      />
    );
  }

  const handleCheck = (c: boolean, id: string) => {
    let ids = [];
    if (isRefTypeData(partObject.data)) {
      if (partObject.data.value.length === 1) {
        ids.push(partObject.id);
      } else {
        ids.push(...partObject.data.value.map((v) => v.diffId));
      }
    } else {
      ids.push(partObject.id);
    }
    selectionCallback(ids, c);
  };

  const getValueComponent = (partObject: PartObject<ValueData>) => {
    if (isRefTypeData(partObject.data)) {
      return <ReferenceTypeTable referred={partObject.data} />;
    } else if (isAttributeData(partObject.data)) {
      return formatValue(partObject.data.value);
    } else {
      return partObject.data.value;
    }
  };

  return (
    <>
      <tr style={{ border: "1px solid #979797" }}>
        <StyledCBCell>
          {canBeApproved(partObject) ? (
            <Checkbox
              id={partObject.id}
              onChange={(event) =>
                handleCheck(event.target.checked, event.target.id)
              }
              checked={selected.includes(partObject.id)}
            />
          ) : undefined}
        </StyledCBCell>
        <StyledCell>{partObject.typeTitle}</StyledCell>
        <StyledCell>{partObject.title}</StyledCell>
        <StyledCell colSpan="3">{getValueComponent(partObject)}</StyledCell>
        <StyledLastCell>
          <ChangeInformation
            responsibleUser={partObject.responsibleUser}
            changed={partObject.changed}
          />
        </StyledLastCell>
      </tr>
    </>
  );
};

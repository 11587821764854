import {registerComponent} from "tim-bridge";
import PartialApprovalDialog from "./components/PartialApprovalDialog";
import {run} from "./runner";

const config = require("../tim-meta.json");
const parameters = require("../app-parameters.json");
const isProduction = process.env.NODE_ENV === "production";
const isStandalone = process.env.BUILD_VARIANT === "standalone";

if (isProduction && !isStandalone) {
    registerComponent(config.packageName, config.componentName, PartialApprovalDialog);
} else {
    run(config.packageName, parameters, isStandalone);
}
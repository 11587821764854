import * as React from "react";
import {
  PartObject,
  ValueData,
  isRefTypeData,
  ReferenceValueData,
} from "./Types";
import { PartObjectsTable } from "./PartObjectsTable";
import { Checkbox, KeyboardArrowDown, KeyboardArrowUp } from "tim-ui";
import styled from "styled-components";
import { sortInGroups, canBeApproved } from "./utils";
import { useTranslation } from "tim-bridge";
interface PartObjectsSectionProps {
  id: string;
  collapserConfig: CollapserConfig;
  selectionConfig: SelectionConfig;
  className: string;
  diffs: PartObject<ValueData>[];
  title: string;
  noChangesString: string;
}

interface CollapserConfig {
  collapsible: boolean;
  collapsed: boolean;
}

interface SelectionConfig {
  selected: string[];
  selectionCallback: (ids: string[], selected: boolean) => void;
}

const SectionHeaderDiv = styled.div`
  padding: 16px;
`;

const SectionH1 = styled.h1`
  display: contents;
  font-size: 24px;
  font-weight: normal;
  margin: 0;
  max-width: 1600px;
  width: calc(100% - 150px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledCheckbox = styled(Checkbox)`
  float: left;
  padding-top: 4px !important;
  padding-right: 8px !important;
`;

const CollapserDiv = styled.div`
  float: right;
  display: flex;
  align-items: center;
  padding-right: 32px;
  padding-top: 6px;
  &:hover {
    cursor: pointer;
  }
`;

const Down = styled(KeyboardArrowDown)`
  // padding-top: 4px;
`;

const Up = styled(KeyboardArrowUp)`
  // padding-top: 4px;
`;

export const PartObjectsSection: React.FC<PartObjectsSectionProps> = (
  props: PartObjectsSectionProps
) => {
  const { t, ready } = useTranslation(["partialapprove"]);

  const seeMore: string = t("PartialApprovalDialog.seeMoreLabel", {
    defaultValue: "See more",
  });

  const seeLess: string = t("PartialApprovalDialog.seeLessLabel", {
    defaultValue: "See less",
  });

  const checked =
    props.diffs.length > 0 &&
    props.diffs
      .filter((po: PartObject<ValueData>) => canBeApproved(po))
      .map((d) => d.id)
      .every((id) => props.selectionConfig.selected.includes(id));

  const handleCheck = (c: boolean) => {
    props.selectionConfig.selectionCallback(
      props.diffs.filter((d) => canBeApproved(d)).map((d) => d.id),
      c
    );
  };

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const [collapsed, setCollapsed] = React.useState<boolean>(
    props.collapserConfig.collapsed
  );
  const sorted: Map<string, PartObject<ValueData>[]> = sortInGroups(
    props.diffs
  );

  const cb =
    props.diffs.filter((po) => canBeApproved(po)).length > 0 ? (
      <StyledCheckbox
        checked={checked}
        onChange={(event) => handleCheck(event.target.checked)}
      />
    ) : null;

  const collapser = props.collapserConfig.collapsible ? (
    <CollapserDiv>
      <div onClick={() => handleCollapse()}>
        {collapsed ? seeMore : seeLess}
      </div>
      {collapsed ? (
        <Down onClick={() => handleCollapse()} />
      ) : (
        <Up onClick={() => handleCollapse()} />
      )}
    </CollapserDiv>
  ) : null;

  const sectionContent = (
    <div>
      {sorted.has("__noGroup") ? (
        <PartObjectsTable
          selectionCallback={props.selectionConfig.selectionCallback}
          title="Description"
          partObjects={sorted.get("__noGroup")}
          selected={props.selectionConfig.selected}
        />
      ) : undefined}
      {sorted.delete("__noGroup")}
      {Array.from(sorted.entries()).map((entry) => (
        <PartObjectsTable
          key={entry[0]}
          selectionCallback={props.selectionConfig.selectionCallback}
          title={entry[0].split(":")[1]}
          partObjects={entry[1]}
          selected={props.selectionConfig.selected}
        />
      ))}
    </div>
  );

  const section = (
    <div id={props.id} className={props.className}>
      <SectionHeaderDiv>
        {collapser}
        {cb}
        <SectionH1>
          {props.diffs.length > 0 ? props.title : props.noChangesString}
        </SectionH1>
      </SectionHeaderDiv>
      {collapsed ? undefined : sectionContent}
    </div>
  );

  return section;
};

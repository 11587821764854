import {
  ReferenceTypeData,
  AttributeData,
  ReferenceValueData,
  ScrollWindow,
} from "./Types";
import * as React from "react";
import { ArrowLeft, ArrowRight } from "tim-ui";
import styled from "styled-components";
import { RowErrorsComponent } from "./RowErrorsComponent";
import { TableRow, TableDiv, FixedCell } from "./styled";
import { getCell } from "./utils";
import { removedFromMainString } from "./PartObjectsDCTableRow";

export interface ReferenceTypeTableProps {
  referred: ReferenceTypeData;
}
export const RefTable = styled.table`
  width: 100%;
  border: 1px solid #979797;
  border-collapse: collapse;
  border-radius: 4px;
`;

const RefTableContainer = styled.div`
  max-width: 100vw;
  overflow: hidden;
  display: flex;
`;

export const RefTableHeader = styled.th`
  text-align: left;
  vertical-align: top;
  padding: 8px;
  font-size: 12px;
  background-color: rgba(133, 133, 133, 0.1);
  border-color: rgba(133, 133, 133, 0.1);
  min-width: 70px;
`;

export const RefTableRow = styled.tr`
  border-bottom: 1px solid #979797;
`;

export const RefTableCell = styled.td`
  text-align: left;
  vertical-align: top;
  padding: 8px;
  font-size: 12px;
`;

const Scroller = styled.div`
  width: 14px;
  border-bottom: 1px solid #979797;
  border-top: 1px solid #979797;
  border-right: 1px solid #979797;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SmallArrowLeft = styled(ArrowLeft)`
  font-size: 16px;
`;

const SmallArrowRight = styled(ArrowRight)`
  font-size: 16px;
`;

export const ReferenceTypeTable = (props: ReferenceTypeTableProps) => {
  const [scrollWindow, setScrollWindow] = React.useState<ScrollWindow>({
    startIndex: 0,
    endIndex: 1,
    size: 2,
    initial: true,
    dir: "none",
  });

  const moveRight = () => {
    if (scrollWindow.endIndex < refValue.value.length - 1) {
      setScrollWindow({
        startIndex: scrollWindow.startIndex + 1,
        endIndex: scrollWindow.endIndex + 1,
        size: scrollWindow.size,
        initial: false,
        dir: "right",
      });
    }
  };

  const moveLeft = () => {
    if (scrollWindow.startIndex > 0) {
      setScrollWindow({
        startIndex: scrollWindow.startIndex - 1,
        endIndex: scrollWindow.endIndex - 1,
        size: scrollWindow.size,
        initial: false,
        dir: "left",
      });
    }
  };

  const createRefTableHeader = (referred: ReferenceValueData) => {
    const size =
      referred.value.length > scrollWindow.size
        ? 100 / (scrollWindow.size + 1)
        : 100 / (referred.value.length + 1);
    return (
      <TableRow header>
        <FixedCell size={size} header>
          <div style={{}}>Target</div>
        </FixedCell>
        {referred.value.map((attribute: AttributeData) =>
          getCell(
            referred.removed,
            scrollWindow,
            referred.value.indexOf(attribute),
            attribute.id,
            attribute.title,
            size,
            true
          )
        )}
      </TableRow>
    );
  };
  const createRefTableRow = (referred: ReferenceValueData) => {
    const size =
      referred.value.length > scrollWindow.size
        ? 100 / (scrollWindow.size + 1)
        : 100 / (referred.value.length + 1);
    return (
      <TableRow
        title={referred.removed ? removedFromMainString : undefined}
        key={"RTR" + referred.target}
        removed={referred.removed}
      >
        <FixedCell size={size} removed={referred.removed}>
          <div id="targetName">{referred.target}</div>
        </FixedCell>
        {referred.value.map((attribute: AttributeData) =>
          getCell(
            referred.removed,
            scrollWindow,
            referred.value.indexOf(attribute),
            referred.target + ":" + attribute.id,
            attribute.value,
            size,
            false
          )
        )}
      </TableRow>
    );
  };

  const refValue = props.referred.value[0];
  return (
    <div>
      <RefTableContainer>
        <TableDiv>
          {createRefTableHeader(refValue)}
          {props.referred.value.map((refValue: ReferenceValueData) =>
            createRefTableRow(refValue)
          )}
        </TableDiv>
        {refValue.value.length > scrollWindow.size ? (
          <>
            <Scroller
              id={props.referred.title + "-prev"}
              onClick={() => moveLeft()}
            >
              <SmallArrowLeft fontSize="inherit" />
            </Scroller>
            <Scroller
              id={props.referred.title + "-next"}
              onClick={() => moveRight()}
            >
              <SmallArrowRight fontSize="inherit" />
            </Scroller>
          </>
        ) : undefined}
      </RefTableContainer>
      <RowErrorsComponent referred={props.referred} />
    </div>
  );
};

import * as React from "react";
import {
  PartObject,
  ValueData,
  NameData,
  AttributeData,
  ReferenceValueData,
  ReferenceTypeData,
  DataContainerValueData,
  DataContainerTypeData,
  isRefTypeData,
  nameType,
  attributeType,
  referenceType,
  attributeLinkType,
  dataContainerType,
} from "./Types";
import { i18n } from "tim-bridge";
import Linkify from "react-linkify";
import {
  DefaultCell,
  appearFromRight,
  disappearToLeft,
  appearFromLeft,
  disappearToRight,
  AnimatedCell,
  MultiValDiv,
} from "./styled";

export const canBeApproved = (po: PartObject<ValueData>) => {
  let isApprovable = true;
  if (isRefTypeData(po.data)) {
    isApprovable =
      po.data.value.filter(
        (ref: ReferenceValueData) => ref.errorString !== undefined
      ).length === 0;
  }

  return po.approvable && isApprovable;
};

export const partition = (array, isValid) => {
  if (array) {
    return array.reduce(
      ([pass, fail], elem) => {
        return isValid(elem)
          ? [[...pass, elem], fail]
          : [pass, [...fail, elem]];
      },
      [[], []]
    );
  } else {
    return [[], []];
  }
};

const nameTypeString: string = i18n.t("PartialApprovalDialog.nameTypeString", {
  defaultValue: "Name",
});

const attributeTypeString: string = i18n.t(
  "PartialApprovalDialog.attributeTypeString",
  {
    defaultValue: "Attribute",
  }
);

const attributeLinkTypeString: string = i18n.t(
  "PartialApprovalDialog.attributeLinkTypeString",
  {
    defaultValue: "Attribute Link",
  }
);
const dcTypeString: string = i18n.t("PartialApprovalDialog.dcTypeString", {
  defaultValue: "Data Container",
});

export const convertTypeTitle = (raw) => {
  const typeID: string = raw.__typename;
  switch (typeID) {
    case referenceType:
      return getReferenceTypeTitle(
        raw.referenceType.validTargetType,
        raw.referenceType.isClassificationLinkType
      );
    case nameType:
      return nameTypeString;
    case attributeType:
      return attributeTypeString;
    case attributeLinkType:
      return attributeLinkTypeString;
    case dataContainerType:
      return dcTypeString;
    default:
      return undefined;
  }
};

const getReferenceTypeTitle = (
  validTargetType: string,
  isClassificationLink: boolean
) => {
  const type = isClassificationLink ? "ClassificationLink" : validTargetType;

  switch (type) {
    case "Asset":
      return i18n.t("PartialApprovalDialog.assetRefTypeString", {
        defaultValue: "Asset Reference Type",
      });
    case "Product":
      return i18n.t("PartialApprovalDialog.productRefTypeString", {
        defaultValue: "Product Reference Type",
      });
    case "Entity":
      return i18n.t("PartialApprovalDialog.entityRefTypeString", {
        defaultValue: "Entity Reference Type",
      });
    case "Classification":
      return i18n.t("PartialApprovalDialog.classificationRefTypeString", {
        defaultValue: "Classification Reference Type",
      });
    case "ClassificationLink":
      return i18n.t("PartialApprovalDialog.classificationLinkTypeString", {
        defaultValue: "Product to Classification Link Type",
      });
  }

  return undefined;
};

export const convertTitle: (diff: any) => string = (diff) => {
  switch (diff.__typename) {
    case nameType:
      return "Name";
    case attributeType:
      return diff.attribute.title;
    case referenceType:
      return diff.referenceType.title;
    case attributeLinkType:
      return diff.attribute.title;
    case dataContainerType:
      return diff.dataContainerType.title;
    default:
      return "";
  }
};
export const convertValue: (diff: any) => ValueData = (diff) => {
  switch (diff.__typename) {
    case nameType: {
      let retval: NameData = { discriminator: "ND", value: diff.mainName };
      return retval;
    }
    case attributeType: {
      let retVal: AttributeData = {
        discriminator: "AD",
        id: diff.attribute.id,
        title: diff.attribute.title,
        value: diff.mainValue.simpleValue
          ? formatTags(diff.mainValue.simpleValue)
          : "",
      };
      return retVal;
    }
    case referenceType: {
      const targetApprovalStatus = diff.mainReference
        ? diff.mainReference.target.approvalStatus
        : undefined;
      const target = diff.mainReference
        ? diff.mainReference.target.title
        : diff.approvedReference.target.title;
      const values: Array<AttributeData> = [];
      diff.mainReference &&
        diff.mainReference.values.forEach((element) => {
          const value: AttributeData = {
            discriminator: "AD",
            id: element.attribute.id,
            title: element.attribute.title,
            value: element.simpleValue,
          };
          values.push(value);
        });
      !diff.mainReference &&
        diff.approvedReference.values.forEach((element) => {
          const value: AttributeData = {
            discriminator: "AD",
            id: element.attribute.id,
            title: element.attribute.title,
            value: undefined,
          };
          values.push(value);
        });

      const refValue: ReferenceValueData = {
        discriminator: "RVD",
        target: target,
        value: values,
        removed: !diff.mainReference,
      };
      if (targetApprovalStatus === "NotInApproved") {
        refValue.errorString =
          target + " as target of reference is not approved";
      }
      const refType: ReferenceTypeData = {
        discriminator: "RTD",
        id: diff.referenceType.id,
        title: diff.referenceType.title,
        value: [refValue],
        validTargetType: diff.referenceType.validTargetType,
        isClassificationLink: diff.referenceType.isClassificationLinkType,
      };
      return refType;
    }
    case attributeLinkType: {
      const target = undefined;
      const values: Array<AttributeData> = [];
      diff.mainAttributeLink &&
        diff.mainAttributeLink.values.forEach((element) => {
          const value: AttributeData = {
            discriminator: "AD",
            id: "Product Attribute Link" + element.attribute.id,
            title: element.attribute.title,
            value: element.simpleValue,
          };
          values.push(value);
        });
      const refValue: ReferenceValueData = {
        discriminator: "RVD",
        target: target,
        value: values,
        removed: !diff.mainAttributeLink,
      };
      const refType: ReferenceTypeData = {
        discriminator: "RTD",
        id: "Product Attribute Link Type" + diff.attribute.id,
        title: "Product Attribute Link Type",
        value: [refValue],
        validTargetType: undefined,
        isClassificationLink: undefined,
      };
      return refType;
    }
    case dataContainerType: {
      const values: AttributeData[] = [];
      const refs: ReferenceTypeData[] = [];
      const removed: boolean = !diff.mainDataContainer;
      const rawVals = removed
        ? diff.approvedDataContainer.values
        : diff.mainDataContainer.values;
      const rawRefs = removed
        ? diff.approvedDataContainer.referencesByReferenceType
        : diff.mainDataContainer.referencesByReferenceType;

      rawVals.forEach((element) => {
        const value: AttributeData = {
          discriminator: "AD",
          id: element.attribute.id,
          title: element.attribute.title,
          value: removed ? undefined : element.simpleValue,
        };
        values.push(value);
      });

      rawRefs.forEach((element) => {
        const value: ReferenceTypeData = {
          discriminator: "RTD",
          id: element.referenceType.id,
          title: element.referenceType.name
            ? element.referenceType.name
            : element.referenceType.title,
          value: element.referenceEntries.map(
            (rE) =>
              ({
                discriminator: "RVD",
                target: rE.target.name
                  ? rE.target.name
                  : "(" + rE.target.id + ")",
                value: [],
              } as ReferenceValueData)
          ),
          validTargetType: element.referenceType.validTargetType,
          isClassificationLink: element.referenceType.isClassificationLinkType,
        };
        refs.push(value);
      });

      const dcValue: DataContainerValueData = {
        refs: refs,
        id: removed ? diff.approvedDataContainer.id : diff.mainDataContainer.id,
        discriminator: "DCVD",
        value: values,
        removed: removed,
      };
      const dataContainer: DataContainerTypeData = {
        discriminator: "DCD",
        id: diff.dataContainerType.id,
        title: diff.dataContainerType.title,
        value: [dcValue],
      };
      return dataContainer;
    }
    default: {
      let retVal: NameData = { discriminator: "ND", value: "" };
      return retVal;
    }
  }
};

export const formatValue = (value: string) => {
  const values = value ? value.split("<multisep/>") : [];
  const formattedValue =
    values.length > 1 ? (
      <div>
        {values.map((singleValue: string) => (
          <MultiValDiv>
            <Linkify
              properties={{ target: "_blank", style: { color: "lightblue" } }}
            >
              {singleValue}
            </Linkify>
          </MultiValDiv>
        ))}
      </div>
    ) : (
      <div>
        <Linkify
          properties={{ target: "_blank", style: { color: "lightblue" } }}
        >
          {value}
        </Linkify>
      </div>
    );
  return formattedValue;
};

const formatTags: (value: string) => string = (value) => {
  const values = value ? value.split("<multisep/>") : [];
  const valueWithoutTags = [];
  values.forEach((s) =>
    valueWithoutTags.push(
      s
        .replace("<prefix>", "")
        .replace("</prefix>", " ")
        .replace("<suffix>", " ")
        .replace("</suffix>", "")
    )
  );
  return valueWithoutTags.join("<multisep/>");
};

export const sortInGroups: (
  d: PartObject<ValueData>[]
) => Map<string, PartObject<ValueData>[]> = (untypedPartObjects) => {
  let groupMap = new Map<string, PartObject<ValueData>[]>();
  untypedPartObjects.forEach((partObject) => {
    partObject.groups.forEach((groupID) => {
      let group = groupMap.get(groupID);
      if (group) {
        group.push(partObject);
      } else {
        group = [partObject];
        groupMap.set(groupID, group);
      }
    });
  });

  return new Map<string, PartObject<ValueData>[]>(
    Array.from(groupMap).sort((a, b) => {
      const left = a[0];
      const right = b[0];
      return left > right ? 1 : -1;
    })
  );
};

const otherGroupString =
  "__otherGroup:" +
  i18n.t("partialapprove:PartialApprovalDialog.ungroupedLabel", {
    defaultValue: "Ungrouped",
  });

const otherReferencesGroupString =
  "__references:" +
  i18n.t("partialapprove:PartialApprovalDialog.referencesLabel", {
    defaultValue: "References",
  });

const otherAttributeLinksGroupString =
  "__attributeLinks:" +
  i18n.t("partialapprove:PartialApprovalDialog.attributeLinksLabel", {
    defaultValue: "Attribute Links",
  });

export const getGroups: (d: any) => Array<string> = (diff) => {
  switch (diff.__typename) {
    case attributeType:
      return getGroupsFrom(diff.attribute.dataTypeGroups, otherGroupString);
    case referenceType:
      return getGroupsFrom(
        diff.referenceType.dataTypeGroups,
        otherReferencesGroupString
      );
    case attributeLinkType:
      return [otherAttributeLinksGroupString];
    case dataContainerType:
      return getGroupsFrom(
        diff.dataContainerType.dataTypeGroups,
        otherGroupString
      );
    // __noGroup is special parts like Name, Object Type etc that does not belong in an attribute group
    default:
      return ["__noGroup"];
  }
};

const getGroupsFrom: (
  dataGroups: any,
  defaultGroup: string
) => Array<string> = (dataGroups, defaultGroup) => {
  const groups = [];
  for (let dataGroupObject of dataGroups) {
    groups.push(dataGroupObject.id + ":" + dataGroupObject.title);
  }
  if (groups.length === 0) {
    groups.push(defaultGroup);
  }
  return groups;
};

// stuff for sliding through scroll window

export const getCell = (
  removed,
  sw,
  index,
  id: string,
  value,
  width: number,
  header: boolean
) => {
  const cellContent = (
    <div id={id} style={{ minHeight: "22px" }}>
      {formatValue(value)}
    </div>
  );
  const left = sw.dir === "left";
  const right = sw.dir === "right";
  const inplace = !(left || right);
  const inWindow = index >= sw.startIndex && index <= sw.endIndex;
  const inStaticWindow = right
    ? index >= sw.startIndex && index < sw.endIndex
    : index > sw.startIndex && index <= sw.endIndex;
  let returnCell = undefined;
  if (inplace) {
    if (inWindow) {
      returnCell = (
        <DefaultCell key={id} size={width} header={header} removed={removed}>
          {cellContent}
        </DefaultCell>
      );
    }
  } else if (inStaticWindow) {
    returnCell = (
      <DefaultCell size={width} key={id} header={header} removed={removed}>
        {cellContent}
      </DefaultCell>
    );
  } else {
    const appearing = right ? index === sw.endIndex : index === sw.startIndex;
    const disappearing = right
      ? index === sw.startIndex - 1
      : index === sw.endIndex + 1;

    if (appearing || disappearing) {
      let animation = undefined;
      if (right) {
        animation = appearing ? appearFromRight(width) : disappearToLeft(width);
      } else {
        animation = appearing ? appearFromLeft(width) : disappearToRight(width);
      }
      returnCell = (
        <AnimatedCell
          size={width}
          animation={animation}
          key={id}
          header={header}
          removed={removed}
        >
          {cellContent}
        </AnimatedCell>
      );
    }
  }
  return returnCell;
};

import React = require("react");
import { ApproveResult, ApproveError, MissingDependency } from "./Types";
import { Error, Link } from "tim-ui";
import styled from "styled-components";
import { WithTranslation, nsTranslate } from "../i18n";

interface ApproveResultComponentProps extends WithTranslation {
  approveResult: ApproveResult;
  currentObjectTitle: string;
  className: string;
  linkResolver: (link: string) => void;
}

const StyledError = styled(Error)`
  color: #d32f2f;
  margin-right: 8px;
  vertical-align: middle;
`;

const CollapserDiv = styled.div`
  margin-top: 16px;
  text-decoration: underline;
  cursor: pointer;
`;

const StyleMessage = styled.div`
  white-space: pre-wrap;
`;

export const InternalApproveResultComponent = (
  props: ApproveResultComponentProps
) => {
  const collapseLimit = 3;
  const { approveResult, t } = props;
  const [depsCollapsed, setDepsCollapsed] = React.useState<boolean>(true);
  const [errorsCollapsed, setErrorsCollapsed] = React.useState<boolean>(true);

  let errorNum: number = approveResult ? approveResult.errors.length : 0;
  let missingDepNum: number = approveResult
    ? approveResult.missingDependencies.length
    : 0;

  const errorsLabel = t("PartialApprovalDialog.errorsLabel", {
    defaultValue: "Errors",
  });

  const depsLabel = t("PartialApprovalDialog.missingDependenciesLabel", {
    defaultValue: "Missing Dependencies",
  });

  const missingMandatoryLinksString = t(
    "PartialApprovalDialog.missingMandatory",
    { defaultValue: "Missing mandatory links: " }
  );

  const seeAllMissingDepsString = t(
    "PartialApprovalDialog.seeAllMissingDepsString",
    {
      defaultValue: "See all missing dependencies",
    }
  );

  const seeLessMissingDepsString = t(
    "PartialApprovalDialog.seeLessMissingDepsString",
    {
      defaultValue: "See less missing dependencies",
    }
  );

  const seeAllErrorsString = t("PartialApprovalDialog.seeAllErrorsString", {
    defaultValue: "See all errors",
  });

  const seeLessErrorsString = t("PartialApprovalDialog.seeLessErrorsString", {
    defaultValue: "See less errors",
  });

  const errorsCollapser =
    approveResult && approveResult.errors.length > collapseLimit ? (
      <CollapserDiv onClick={() => setErrorsCollapsed(!errorsCollapsed)}>
        {errorsCollapsed ? seeAllErrorsString : seeLessErrorsString}
      </CollapserDiv>
    ) : undefined;

  const depsCollapser =
    approveResult &&
    approveResult.missingDependencies.length > collapseLimit ? (
      <CollapserDiv onClick={() => setDepsCollapsed(!depsCollapsed)}>
        {depsCollapsed ? seeAllMissingDepsString : seeLessMissingDepsString}
      </CollapserDiv>
    ) : undefined;

  const getIdFromStepURL: (stepURL: string) => string = (url: string) => {
    const res = url.match(/[{?, &}]id=([^{&, ,\n,\r}]*)/);
    console.log(res);
    if (res.length > 0) {
      return res[1];
    } else {
      return url;
    }
  };

  const getLinkifiedMsg: (msg: string) => JSX.Element = (msg: string) => {
    const thingsToReplace = new Map<string, string>();
    thingsToReplace[
      "com.stibo.core.domain.impl.MandatoryLinksApproveValidator : "
    ] = missingMandatoryLinksString;

    thingsToReplace.forEach((k, v) => (msg = msg.replace(k, v)));

    const parts: string[] = msg.split(/(step:\/\/[^{\], ,\n}]*)/g);
    const linkifiedParts: any[] = [...parts];

    for (let i = 1; i < parts.length; i += 2) {
      linkifiedParts[i] = (
        <Link onClick={() => props.linkResolver(parts[i])} key={i}>
          {getIdFromStepURL(parts[i]).replace(/\+/g, " ")}
        </Link>
      );
    }

    return <div>{linkifiedParts}</div>;
  };

  return (
    <div id="approveResult" className="approveResult">
      {approveResult ? (
        <div className={props.className}>
          {errorNum > 0 ? (
            <div>
              <h3>
                <StyledError />
                {errorsLabel}
                {errorNum > collapseLimit ? " (" + errorNum + ")" : undefined}
              </h3>
              <div style={{ marginLeft: "32px" }}>
                {[...approveResult.errors]
                  .slice(0, errorsCollapsed ? collapseLimit : errorNum)
                  .map((error, index) => (
                    <StyleMessage key={index}>
                      {getLinkifiedMsg(error.message)}
                    </StyleMessage>
                  ))}
              </div>
              {errorsCollapser}
            </div>
          ) : undefined}
          {missingDepNum > 0 ? (
            <div>
              <h3>
                <StyledError />
                {depsLabel}
                {missingDepNum > collapseLimit
                  ? " (" + missingDepNum + ")"
                  : undefined}
              </h3>
              <div style={{ marginLeft: "32px" }}>
                {[...approveResult.missingDependencies]
                  .slice(0, depsCollapsed ? collapseLimit : missingDepNum)
                  .map((missingDependency, index) => (
                    <StyleMessage key={index}>
                      {getLinkifiedMsg(missingDependency.message)}
                    </StyleMessage>
                  ))}
                {depsCollapser}
              </div>
            </div>
          ) : undefined}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const ApproveResultComponent = nsTranslate(InternalApproveResultComponent);
export default ApproveResultComponent;

import gql from "graphql-tag";

export const GET_WORKSPACE_DIFF = gql`
  query getWorkspaceDiff(
    $contextStepId: String!
    $nodeType: WorkspaceDifferencesType!
    $stepId: String!
  ) {
    workspaceDifferences(
      context: $contextStepId
      node: $stepId
      nodeType: $nodeType
    ) {
      id
      __typename
      responsibleUser
      changed
      approvable
      ... on NameWorkspaceDifference {
        mainName
      }
      ... on ValueWorkspaceDifference {
        attribute {
          id
          title
          dataTypeGroups {
            id
            title
          }
        }
        mainValue {
          simpleValue
        }
        approvedValue {
          simpleValue
        }
      }
      ... on ReferenceWorkspaceDifference {
        referenceType {
          id
          title
          validTargetType
          isClassificationLinkType
          dataTypeGroups {
            id
            title
          }
        }
        approvedReference {
          values {
            attribute {
              id
              title
            }
            simpleValue
          }
          target {
            ... on Asset {
              id
              title
              approvalStatus
            }
            ... on Product {
              id
              title
              approvalStatus
            }
            ... on Entity {
              id
              title
              approvalStatus
            }
            ... on Classification {
              id
              title
              approvalStatus
            }
          }
        }
        mainReference {
          values {
            attribute {
              id
              title
            }
            simpleValue
          }
          target {
            ... on Asset {
              id
              title
              approvalStatus
            }
            ... on Product {
              id
              title
              approvalStatus
            }
            ... on Entity {
              id
              title
              approvalStatus
            }
            ... on Classification {
              id
              title
              approvalStatus
            }
          }
        }
      }
      ... on AttributeLinkWorkspaceDifference {
        attribute {
          id
          title
        }
        mainAttributeLink {
          values {
            attribute {
              id
              title
            }
            simpleValue
          }
          attribute {
            id
          }
        }
      }
      ... on DataContainerWorkspaceDifference {
        dataContainerType {
          id
          title
          dataTypeGroups {
            id
            title
          }
        }
        approvedDataContainer {
          id
          values {
            attribute {
              id
              title
            }
            simpleValue
          }
          referencesByReferenceType {
            referenceType {
              id
              title
              name
              validTargetType
            }
            referenceEntries {
              target {
                ... on Product {
                  id
                  name
                }
                ... on Asset {
                  id
                  name
                }
                ... on Classification {
                  id
                  name
                }
                ... on Entity {
                  id
                  name
                }
              }
            }
          }
        }
        mainDataContainer {
          id
          values {
            attribute {
              id
              title
            }
            simpleValue
          }
          referencesByReferenceType {
            referenceType {
              id
              title
              name
            }
            referenceEntries {
              target {
                ... on Product {
                  id
                  name
                }
                ... on Asset {
                  id
                  name
                }
                ... on Classification {
                  id
                  name
                }
                ... on Entity {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const APPROVE_WORKSPACE_DIFF = gql`
  mutation approveWorkspaceDiff(
    $contextStepId: String!
    $input: ApproveDifferencesInput!
  ) {
    approveDifferences(context: $contextStepId, input: $input) {
      success
      errors {
        path
        message
      }
      missingDependencies {
        message
        node {
          id
          name
          title
        }
      }
    }
  }
`;

import { ReferenceTypeTableProps } from "./ReferenceTypeTable";
import { ReferenceValueData } from "./Types";
import * as React from "react";
import { RowErrorComponent } from "./RowErrorComponent";
import styled from "styled-components";
import { Error, Warning } from "tim-ui";

const CollapserDiv = styled.div`
  margin-top: 8px;
  margin-left: 32px;
  text-decoration: underline;
  cursor: pointer;
  color: #0071b4;
  font-size: 12px;
`;
const StyledWarning = styled(Warning)`
  color: #f9a825;
  margin: 4px 8px 4px 0;
  // margin-right: 8px;
  vertical-align: middle;
`;
const ErrorDiv = styled.div`
  padding-top: 8px;
  font-size: 12px;
`;
export const RowErrorsComponent = (props: ReferenceTypeTableProps) => {
  const collapseLimit = 3;

  const [errorsCollapsed, setErrorsCollapsed] = React.useState<boolean>(true);
  const refsWithErrors = props.referred.value.filter(
    (refValue: ReferenceValueData) => refValue.errorString
  );
  const errorsCollapser =
    props.referred.value && refsWithErrors.length > collapseLimit ? (
      <CollapserDiv onClick={() => setErrorsCollapsed(!errorsCollapsed)}>
        {errorsCollapsed
          ? "See all errors(" + refsWithErrors.length + ")"
          : "See less errors"}
      </CollapserDiv>
    ) : (
      undefined
    );
  const numberOfErrors: number = refsWithErrors.length;
  return (
    <>
      {numberOfErrors > 0 ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {/* <div>
            <StyledWarning />
          </div> */}
          {refsWithErrors
            .slice(0, errorsCollapsed ? collapseLimit : refsWithErrors.length)
            .map((refValue: ReferenceValueData, index) => (
              <div style={{ display: "flex" }}>
                <StyledWarning />
                <ErrorDiv key={index}>{refValue.errorString}</ErrorDiv>
              </div>
            ))}
          {errorsCollapser}
        </div>
      ) : (
        undefined
      )}
    </>
  );
};

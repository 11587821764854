import * as React from "react";
interface ChangeInformationProps {
    responsibleUser: string;
    changed: Date;
  }
const ChangeInformation = (props: ChangeInformationProps) => {
    const {
        responsibleUser, changed
    } = props;
    const changedString = changed.toLocaleDateString() + " " + changed.toLocaleTimeString();
    return <div>Made by <b>{responsibleUser}</b> on {changedString}</div>;
};

export default ChangeInformation;
import * as React from "react";
import * as ReactDOM from "react-dom";
import PartialApprovalDialog, {AppWithHotReload} from "./components/PartialApprovalDialog";
const getDevComponent = require("tim-bridge").getDevComponent;

export const run = (componentName, parameters, isStandalone) => {
    const AppWrapper = getDevComponent(isStandalone ? PartialApprovalDialog : AppWithHotReload, {
        env: process.env,
        parameters
    }, componentName);

    ReactDOM.render(
        <AppWrapper/>,
        document.getElementById(AppWrapper.containerId)
    );
};
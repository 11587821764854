import * as React from "react";

interface SubHeadingProps {
  title: string;
}
const SubHeading = (props: SubHeadingProps) => {
  return (
    <div
      className="SubHeading"
      style={{
        textAlign: "left",
        color: "rgba(0,0,0,0.87)",
        fontWeight: "normal",
        fontSize: "16px"
      }}
    >
      {props.title}
    </div>
  );
};

export default SubHeading;

import {
  PartObject,
  ReferenceTypeData,
  ValueData,
  isRefTypeData,
  DataContainerTypeData,
  ReferenceValueData,
  DataContainerValueData,
  isDCTypeData,
  referenceType,
  dataContainerType,
  attributeLinkType,
  otherType,
} from "./Types";

import SubHeading from "./SubHeading";
import React = require("react");
import { PartObjectsTableRow } from "./PartObjectsTableRow";
import { Checkbox } from "tim-ui";
import styled from "styled-components";
import { useTranslation } from "tim-bridge";
import ChangeInformation from "./ChangeInformation";
import { canBeApproved } from "./utils";

interface PartObjectTableProps {
  title: string;
  selected: string[];
  selectionCallback: (ids: string[], selected: boolean) => void;
  partObjects: PartObject<ValueData>[];
}

const ContainerDiv = styled.div`
  margin-bottom: 16px;
`;

const StyledTable = styled.table`
  width: 100%;
  max-width: calc(100% - 96px);
  margin-left: 48px;
  border: 1px solid #979797;
  border-radius: 4px;
  border-collapse: collapse;
`;

const StyledTH1 = styled.th`
  font-size: 16px;
  padding-top: 8px;
`;

const StyledTH1CB = styled.th`
  padding-top: 8px;
  padding-left: 16px;
  text-align: left;
`;

const StyledTH2 = styled.th`
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
`;

const StyledLastTH2 = styled(StyledTH2)`
  padding-left: 16px;
  width: 300px;
`;

export const StyledCell = styled.td`
  padding: 8px;
  text-align: left;
`;

export const PartObjectsTable: React.FC<PartObjectTableProps> = (
  props: PartObjectTableProps
) => {
  const { t, ready } = useTranslation(["partialapprove"]);
  const typeLabel: string = t("PartialApprovalDialog.typeLabel", {
    defaultValue: "Type",
  });
  const nameLabel: string = t("PartialApprovalDialog.nameLabel", {
    defaultValue: "Name",
  });
  const valueLabel: string = t("PartialApprovalDialog.valueLabel", {
    defaultValue: "Value",
  });
  const changeInfoLabel: string = t("PartialApprovalDialog.changeInfoLabel", {
    defaultValue: "Change Information",
  });

  const partitionedPartObjects = props.partObjects.reduce(
    (
      typeMap: Map<string, PartObject<ValueData>[]>,
      obj: PartObject<ValueData>
    ) => {
      let isOther: boolean = ![
        referenceType,
        dataContainerType,
        attributeLinkType,
      ].includes(obj.typeID);
      let k = isOther ? otherType : obj.typeID;
      let existing = typeMap.get(k);
      if (existing) {
        existing.push(obj);
      } else {
        typeMap.set(k, [obj]);
      }
      return typeMap;
    },
    new Map<string, PartObject<ValueData>[]>()
  );
  const referenceDiffs: PartObject<ReferenceTypeData>[] =
    (partitionedPartObjects.get(referenceType) as PartObject<
      ReferenceTypeData
    >[]) || [];
  const attrLinkDiffs: PartObject<ReferenceTypeData>[] =
    (partitionedPartObjects.get(attributeLinkType) as PartObject<
      ReferenceTypeData
    >[]) || [];
  const dcDiffs: PartObject<DataContainerTypeData>[] =
    (partitionedPartObjects.get(dataContainerType) as PartObject<
      DataContainerTypeData
    >[]) || [];
  const otherDiffs: PartObject<ValueData>[] =
    partitionedPartObjects.get(otherType) || [];

  let mergedReferenceDiffs: PartObject<
    ReferenceTypeData
  >[] = mergeReferenceOrDCDiffs(referenceDiffs) as PartObject<
    ReferenceTypeData
  >[];
  let mergedAttrLinkDiffs: PartObject<
    ReferenceTypeData
  >[] = mergeReferenceOrDCDiffs(attrLinkDiffs) as PartObject<
    ReferenceTypeData
  >[];

  let mergedDCDiffs: PartObject<
    DataContainerTypeData
  >[] = mergeReferenceOrDCDiffs(dcDiffs) as PartObject<DataContainerTypeData>[];

  let partObjects = otherDiffs
    .concat(mergedReferenceDiffs)
    .concat(mergedDCDiffs)
    .concat(mergedAttrLinkDiffs);

  const compare: (
    left: PartObject<ValueData>,
    right: PartObject<ValueData>
  ) => number = (left, right) => {
    const leftTitle = left.title;
    const rightTitle = right.title;
    let r =
      leftTitle !== rightTitle ? leftTitle > rightTitle : left.id > right.id;
    return r ? 1 : -1;
  };
  let checked =
    props.partObjects.length > 0 &&
    props.partObjects
      .filter((po: PartObject<ValueData>) => canBeApproved(po))
      .map((po: PartObject<ValueData>) => po.id)
      .every((id: string) => props.selected.includes(id));

  const handleCheck = (c: boolean) => {
    let unmergedDiffsIds: string[] = [];
    partObjects
      .filter((po: PartObject<ValueData>) => canBeApproved(po))
      .forEach((d) => {
        if (isRefTypeData(d.data)) {
          d.data.value.forEach((v) => unmergedDiffsIds.push(v.diffId));
        } else if (isDCTypeData(d.data)) {
          d.data.value.forEach((v) => unmergedDiffsIds.push(v.diffId));
        } else {
          unmergedDiffsIds.push(d.id);
        }
      });
    props.selectionCallback(unmergedDiffsIds, c);
  };

  let cb =
    partObjects.filter((po: PartObject<ValueData>) => canBeApproved(po))
      .length > 0 ? (
      <Checkbox
        onChange={(event) => handleCheck(event.target.checked)}
        checked={checked}
      />
    ) : null;

  let table =
    partObjects.length > 0 ? (
      <StyledTable id={props.title}>
        <thead>
          <tr style={{ borderBottom: 0 }}>
            <StyledTH1CB>{cb}</StyledTH1CB>
            <StyledTH1 colSpan={6}>
              <SubHeading title={props.title} />
            </StyledTH1>
          </tr>
          <tr>
            <StyledTH2 style={{ width: "48px" }}></StyledTH2>
            <StyledTH2 style={{ width: "200px" }}>{typeLabel}</StyledTH2>
            <StyledTH2 style={{ width: "240px" }}>{nameLabel}</StyledTH2>
            <StyledTH2
              colSpan="3"
              style={{ minWidth: "240px", maxWidth: "600px" }}
            >
              {valueLabel}
            </StyledTH2>
            <StyledLastTH2>{changeInfoLabel}</StyledLastTH2>
          </tr>
        </thead>
        <tbody>
          {partObjects
            .sort((left, right) => {
              return compare(left, right);
            })
            .map((partObject: PartObject<ValueData>) => (
              <PartObjectsTableRow
                key={"pto" + partObject.id}
                partObject={partObject}
                selectionCallback={props.selectionCallback}
                selected={props.selected}
              />
            ))}
        </tbody>
      </StyledTable>
    ) : null;

  return <ContainerDiv>{table}</ContainerDiv>;
};

const mergeReferenceOrDCDiffs = (
  diffs: PartObject<ReferenceTypeData | DataContainerTypeData>[]
) => {
  const diffTypeMap = new Map<
    string,
    PartObject<ReferenceTypeData | DataContainerTypeData>
  >();
  if (diffs) {
    diffs.forEach((diff) => {
      let existingDiffType = diffTypeMap.get(diff.data.id);
      if (existingDiffType) {
        diff.data.value.forEach(
          (v: ReferenceValueData & DataContainerValueData) => {
            v.diffId = diff.id;
            v.info = (
              <ChangeInformation
                responsibleUser={diff.responsibleUser}
                changed={diff.changed}
              />
            );
            existingDiffType.data.value.push(v);
          }
        );
      } else {
        diff.data.value.forEach((v) => {
          v.diffId = diff.id;
          v.info = (
            <ChangeInformation
              responsibleUser={diff.responsibleUser}
              changed={diff.changed}
            />
          );
        });
        const valueCopy = [].concat(diff.data.value);
        const dataCopy = { ...diff.data };
        const diffCopy = { ...diff };
        dataCopy.value = valueCopy;
        diffCopy.data = dataCopy;
        diffTypeMap.set(diff.data.id, diffCopy);
      }
    });
    return Array.from(diffTypeMap.values());
  }
  return [];
};

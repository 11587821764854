import { Checkbox, ArrowLeft, ArrowRight } from "tim-ui";
import styled, { keyframes } from "styled-components";

export const MultiValDiv = styled.div`
  margin-bottom: 8px;
`;

export const SmallArrowLeft = styled(ArrowLeft)`
  font-size: 16px;
`;

export const SmallArrowRight = styled(ArrowRight)`
  font-size: 16px;
`;

export const TableRow = styled.div`
  overflow: hidden;
  display: flex;
  width: 100%;
  border-top: ${props => (props.header ? "0px" : "1px solid #979797")};
  background-color: ${props =>
    props.header ? "rgb(243, 243, 243)" : props.removed ? "#f4c9c9" : "white"};
  font-weight: bold;
`;

export const TableDiv = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  border: 1px solid #979797;
  box-sizing: border-box;

  ${TableRow}:nth-child(1) {
    border-top: 0px solid;
  }

  ${TableRow}:nth-child(2) {
    border-top: 0px solid;
  }
`;

export const disappearToLeft = (size: number) => keyframes`
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -${size}%;
  }`;

export const disappearToRight = (size: number) => keyframes`
  0% {
    margin-right: 0px;
  }
  100% {
    margin-right : -${size}%;
  }`;

export const appearFromLeft = (size: number) => keyframes`
  0% {
    margin-left: -${size}%;
  }
  100% {
    margin-left: 0px;
  }`;

export const appearFromRight = (size: number) => keyframes`
  0% {
    margin-right: -${size}%;
  }
  100% {
    margin-right : 0px;
  }`;

export const DefaultCell = styled.div`
  text-align: left;
  vertical-align: top;
  font-size: 12px;
  font-weight: ${props => (props.header ? "bold" : "normal")};
  width: ${props => props.size + "%"};
  min-height: 22px;
  padding: 8px;
  padding-top: 12px;
  background-color: ${props =>
    props.header ? "rgb(243, 243, 243)" : props.removed ? "#f4c9c9" : "white"};
  z-index: 0;
`;

export const FixedCell = styled(DefaultCell)`
  z-index: 1;
`;

export const AnimatedCell = styled(DefaultCell)`
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
  animation-name: ${props => props.animation};
`;

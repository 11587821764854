import ChangeInformation from "./ChangeInformation";

export const attributeLinkType: string = "AttributeLinkWorkspaceDifference";
export const attributeType: string = "ValueWorkspaceDifference";
export const dataContainerType: string = "DataContainerWorkspaceDifference";
export const referenceType: string = "ReferenceWorkspaceDifference";
export const nameType: string = "NameWorkspaceDifference";
export const workspaceType: string = "WorkspaceDifference";
export const otherType: string = "otherType";

export const validPartObjectTypes: string[] = [
  nameType,
  attributeType,
  referenceType,
  attributeLinkType,
  dataContainerType,
  workspaceType,
];

export interface PartObject<T extends ValueData> {
  id: string;
  responsibleUser: string;
  approvable: boolean;
  changed: Date;
  typeID: string;
  typeTitle: string;
  title: string;
  data: T;
  groups: string[];
}

export function isAttributeData(data: ValueData): data is AttributeData {
  return data.discriminator === "AD";
}

export function isRefTypeData(data: ValueData): data is ReferenceTypeData {
  return data.discriminator === "RTD";
}

export function isRefValueData(data: ValueData): data is ReferenceValueData {
  return data.discriminator === "RVD";
}

export function isDCTypeData(data: ValueData): data is DataContainerTypeData {
  return data.discriminator === "DCD";
}

export interface HasValue {
  id: string;
  title: string;
  value: any;
}

export type ValueData =
  | NameData
  | AttributeData
  | ReferenceValueData
  | ReferenceTypeData
  | DataContainerTypeData;

export interface NameData {
  discriminator: "ND";
  value: string;
}

export interface AttributeData {
  discriminator: "AD";
  value: string;
  id: string;
  title: string;
}
export interface ReferenceValueData {
  discriminator: "RVD";
  diffId?: string;
  removed: boolean;
  target: string;
  value: AttributeData[];
  errorString?: string;
}
export interface ReferenceTypeData {
  discriminator: "RTD";
  id: string;
  title: string;
  validTargetType: string;
  isClassificationLink: boolean;
  value: ReferenceValueData[];
}
export interface DataContainerValueData {
  discriminator: "DCVD";
  id: string;
  diffId?: string;
  info?: JSX.Element;
  value: AttributeData[];
  refs: ReferenceTypeData[];
  removed: boolean;
}
export interface DataContainerTypeData {
  discriminator: "DCD";
  id: string;
  title: string;
  value: DataContainerValueData[];
}

export interface ApproveResult {
  success: boolean;
  errors: ApproveError[];
  missingDependencies: MissingDependency[];
}
export interface ApproveError {
  message: string;
  path: string;
}
export interface MissingDependency {
  message: string;
  node: { id: string; title: string };
}
export interface ScrollWindow {
  startIndex: number;
  endIndex: number;
  size: number;
  initial: boolean;
  dir: string;
}
